<template>
  <MainLayout class="service-group-page">
    <template v-if="data" v-slot:navbar-title>
      Группы объектов – "{{ title }}"
    </template>

    <template v-if="data" v-slot:navbar-btn-wrap>
      <button
        @click="onDeleteServiceGroup"
        class="btn btn-delete btn_color_red"
      >
        удалить группу
      </button>
      <router-link
        v-if="urlQuery && urlQuery.id"
        :to="`/editServiceGroup/` + urlQuery.id"
        class="btn btn-edit btn_color_grey"
        >редактировать</router-link
      >
    </template>

    <template v-slot:default>
      <TableFilters :queries="queries" :loading="isDateLoaded">
        <template v-slot:filter-title>
          Техник -
          {{ data.specialist_details ? data.specialist_details.full_name : "" }}
          <span class="filter__title__phone">{{
            data.specialist_details ? phone : ""
          }}</span>
          <span class="title-filter">Всего:</span>{{ sourceObject.count }}
          <!--          <span>объектов</span>-->
        </template>
        <template v-slot:download-button>
          <v-btn
            @click="downloadList"
            class="btn btn_color_grey btn-unload flex-right mr-4"
            :disabled="loadingBtn || sourceObject.results.length == 0"
            :loading="loadingBtn"
          >
            Выгрузить
          </v-btn>
        </template>
      </TableFilters>
      <TableLayout
        :loading="isDateLoaded"
        :table-data="sourceObject.results"
        :header="header"
        toRaw="objects"
        :source-object="sourceObject"
      />
    </template>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import TableLayout from "@/components/tables/TableLayout";
import * as query from "@/helpers/query";
import TableFilters from "@/components/tables/TableFilters";
import tableColsServiceGroupObjects from "@/helpers/tableCols/serviceGroupObjects";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";
// service_group

export default {
  name: "ServiceGroupPage",
  data() {
    return {
      loadingBtn: false,
      queries: query.serviceGroupObjects,
      type: "default"
    };
  },
  components: {
    TableFilters,
    TableLayout,
    MainLayout
  },
  computed: {
    phone() {
      return phoneFilter.execute(this?.data.specialist_details.phone_number);
    },
    data() {
      return this.$store.getters.getServiceGroupInfo;
    },
    title() {
      return this.data.name
        ? this.data.name
        : `Группа объектов ${this.data.id}`;
    },
    sourceObject() {
      return this.$store.getters.getServiceGroupObjects;
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    header() {
      return tableColsServiceGroupObjects.filter(el =>
        el.userRole.includes(this.$store.getters.getCurrentRole)
      );
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadServiceGroupObjectsList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    },
    onDeleteServiceGroup() {
      var isDelete = confirm(
        `Группа объектов "${this.title}" будет удалена. Все объекты удаленной группы останутся в системе, но будут откреплены от назначенного на них техника.`
      );
      if (isDelete)
        this.$store
          .dispatch("deleteServiceGroup", {
            id: this.data.id
          })
          .then(() => {
            this.$store.dispatch(
              "successNotification",
              actionMessage.remove(successMessageDictionary.serviceGroup)
            );
          });
    }
  },
  created() {
    this.$store.dispatch("fetchPageServiceGroupInfo", {
      id: this.urlQuery.id
    });

    this.$store.commit("setSearchQuery", {
      query: {
        ...this.urlQuery.query,
        service_group: this.urlQuery.id
      }
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchPageServiceGroupObjects").finally(() => {
      this.isDateLoaded = false;
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (to.path === from.path) {
      this.$store.dispatch("fetchPageServiceGroupInfo", {
        id: this.urlQuery.id
      });

      this.$store.commit("setSearchQuery", {
        query: {
          ...to.query,
          service_group: this.urlQuery.id
        }
      });
      this.isDateLoaded = true;
      this.$store.dispatch("fetchPageServiceGroupObjects").finally(() => {
        this.isDateLoaded = false;
      });
    }
    next();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearServiceGroupInfo");
    this.$store.dispatch("clearServiceGroupObjects");
    next();
  }
};
</script>

<style lang="scss">
.service-group-page {
  .filter__title {
    display: block;

    &__phone {
      font-weight: bold;
      font-size: 16px;
      color: #9ba3b7;
      display: block;
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}
</style>
